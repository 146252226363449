<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <!-- <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon> -->
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <Inventories />
      </v-tab-item>

      <v-tab-item>
        <MoveProduct />
      </v-tab-item>

      <v-tab-item>
        <MinimumProduct />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

// pages
import Inventories from '../product-data/Inventories.vue'
import MoveProduct from '../product-data/MoveProduct.vue'
import MinimumProduct from '../product-data/MinimumProduct.vue'

export default {
  components: {
    Inventories,
    MoveProduct,
    MinimumProduct,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [{ title: 'สินค้าคงเหลือ' }, { title: 'ความเคลื่อนไหวสินค้า' }, { title: 'สินค้าคงคลังขั้นต่ำ' }]

    return {
      tab,
      tabs,
    }
  },
  data: () => ({
    items: [
      {
        text: 'สินค้า',
        disabled: false,
        href: 'product',
      },
      {
        text: 'สต๊อก',
        disabled: true,
        href: 'stock',
      },
    ],
  }),
}
</script>

<style></style>
