<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <!-- ปุ่มเลือกสินค้า -->
          <v-select dense outlined hide-details label="ช่วงเวลา" :items="year" class="me-2"></v-select>
          <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
        </div>
        <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
          <v-icon class="me-2">
            {{ icons.mdiCheck }}
          </v-icon>
          รันรีพอร์ต
        </v-btn>
      </div>
      <hr class="mt-5 mb-5" />
      <h2 class="text-center">รายงานความเคลื่อนไหวของสินค้า</h2>
      <h3 class="text-center">{{ name }}</h3>
      <p class="text-center mb-0 mt-2">{{ currentDate() }}</p>
      <hr class="mt-5 mb-5" />
      <div>
        <h3>ความเคลื่อนไหวของสินค้า</h3>
        <p class="mb-0">สรุปความเคลื่อนไหวของสินค้าในสต๊อก</p>
        <v-row>
          <v-col v-if="graphInventories" cols="12" sm="8" md="8">
            <InventoriesGraph />
          </v-col>
          <v-col v-if="graphProductIn" cols="12" sm="8" md="8">
            <ProductIn />
          </v-col>
          <v-col v-if="graphProductOut" cols="12" sm="8" md="8">
            <ProductOut />
          </v-col>
          <v-col md="4" sm="4" cols="12">
            <div class="d-flex justify-space-between align-center mb-3 textRate" @click="switchGraph(1)">
              <div>
                <h4>สินค้าคงเหลือ</h4>
                <h2>{{ countProduct }}</h2>
              </div>
              <p v-if="averageInventories < 0" class="mb-0 feeDown">
                <v-icon class="feeDown">{{ icons.mdiMenuDown }}</v-icon>
                {{ averageInventories }} %
              </p>
              <p v-if="averageInventories > 0" class="mb-0 feeUp">
                <v-icon class="feeUp">{{ icons.mdiMenuUp }}</v-icon>
                {{ averageInventories }} %
              </p>
            </div>
            <div class="d-flex justify-space-between align-center mb-3 textRate" @click="switchGraph(2)">
              <div>
                <h4>สินค้าที่รับเข้า</h4>
                <h2>{{ countProductIn }}</h2>
              </div>
              <p v-if="averageProductIn < 0" class="mb-0 feeDown">
                <v-icon class="feeDown">{{ icons.mdiMenuDown }}</v-icon>
                {{ averageProductIn }} %
              </p>
              <p v-if="averageProductIn > 0" class="mb-0 feeUp">
                <v-icon class="feeUp">{{ icons.mdiMenuUp }}</v-icon>
                {{ averageProductIn }} %
              </p>
            </div>
            <div class="d-flex justify-space-between align-center textRate" @click="switchGraph(3)">
              <div>
                <h4>สินค้าที่จ่ายออก</h4>
                <h2>{{ countProductOut }}</h2>
              </div>
              <p v-if="averageProductOut < 0" class="mb-0 feeDown">
                <v-icon class="feeDown">{{ icons.mdiMenuDown }}</v-icon>
                {{ averageProductOut }} %
              </p>
              <p v-if="averageProductOut > 0" class="mb-0 feeUp">
                <v-icon class="feeUp">{{ icons.mdiMenuUp }}</v-icon>
                {{ averageProductOut }} %
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <hr class="mb-5 mt-5" />
      <!-- ตารางรายงานสินค้าคงเหลือ -->
      <template>
        <v-card>
          <v-card-title>
            Case Shop
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="desserts" :search="search"></v-data-table>
        </v-card>
      </template>
      <!-- สิ้นสุดตารางรายงานสินค้าคงเหลือ -->
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mdiCheck, mdiMenuUp, mdiMenuDown } from '@mdi/js'
import InventoriesGraph from './datagraph/InventoriesGraph.vue'
import ProductIn from './datagraph/ProductIn.vue'
import ProductOut from './datagraph/ProductOut.vue'

export default {
  components: { DatePicker, InventoriesGraph, ProductIn, ProductOut },
  setup() {
    const year = [
      'เลือกช่วงเวลา',
      'กำหนดเอง',
      'เดือนนี้',
      'ไตรมาสนี้',
      ' ปีปัจจุบัน',
      'ปีก่อนหน้า',
      'เดือนนี้ถึงวันนี้',
      'ไตรมาสนี้ถึงวันนี้',
      'ปีนี้ถึงวันนี้',
      'ปีงบประมาณปัจจุบัน',
      'ปีงบประมาณก่อนหน้า',
      '12 เดือนย้อนหลัง',
    ]

    return {
      year,
      icons: {
        mdiCheck,
        mdiMenuUp,
        mdiMenuDown,
      },
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      name: 'Case',
      search: '',
      totalSales: 300000000,
      graphInventories: true,
      graphProductIn: false,
      graphProductOut: false,
      averageInventories: 2,
      averageProductIn: -1,
      averageProductOut: 10,
      countProduct: 10,
      countProductIn: 20,
      countProductOut: 30,
      desserts: [
        {
          name: 'Frozen Yogurt',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Ice cream sandwich',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Eclair',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Cupcake',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Gingerbread',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Jelly bean',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Lollipop',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Honeycomb',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Donut',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'KitKat',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Lollipop',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'KitKat',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Donut',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'KitKat',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Jelly bean',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
        {
          name: 'Ice cream sandwich',
          valueTop: '-',
          buy: 200,
          sell: 300,
          remain: 20,
          cost: 200,
          averageCost: 250,
        },
      ],
      headers: [
        {
          text: 'สินค้า',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'ยอดยกมา', value: 'valueTop' },
        { text: 'ซื้อ', value: 'buy' },
        { text: 'ขาย', value: 'sell' },
        { text: 'คงเหลือ', value: 'remain' },
        { text: 'ต้นทุน', value: 'cost' },
        { text: 'ต้นทุนเฉลี่ย/หน่วย', value: 'averageCost' },
      ],
    }
  },
  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    },
    switchGraph(id) {
      if (id === 2) {
        this.graphInventories = false
        this.graphProductIn = true
        this.graphProductOut = false
      } else if (id === 3) {
        this.graphInventories = false
        this.graphProductIn = false
        this.graphProductOut = true
      } else {
        this.graphInventories = true
        this.graphProductIn = false
        this.graphProductOut = false
      }
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.textRate {
  /* background-color: #8a8d93; */
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.textRate:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: scale(1.01);
}
.feeDown {
  color: red;
}
.feeUp {
  color: #8dbc8a;
}
</style>
