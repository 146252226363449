<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <!-- ปุ่มเลือกสินค้า -->
          <v-select dense outlined hide-details label="ช่วงเวลา" :items="year" class="me-2"></v-select>
          <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
        </div>
        <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
          <v-icon class="me-2">
            {{ icons.mdiCheck }}
          </v-icon>
          รันรีพอร์ต
        </v-btn>
      </div>
      <hr class="mt-5 mb-5" />
      <h2 class="text-center">รายงานสินค้าคงคลังขั้นต่ำ</h2>
      <h3 class="text-center">{{ name }}</h3>
      <p class="text-center mb-0 mt-2">{{ currentDate() }}</p>
      <hr class="mt-5 mb-5" />
      <!-- ตารางรายงานสินค้าคงเหลือ -->
      <template>
        <v-card>
          <v-card-title>
            Case Shop
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="desserts" :search="search"></v-data-table>
        </v-card>
      </template>
      <!-- สิ้นสุดตารางรายงานสินค้าคงเหลือ -->
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mdiCheck } from '@mdi/js'

export default {
  components: { DatePicker },
  setup() {
    const year = [
      'เลือกช่วงเวลา',
      'กำหนดเอง',
      'เดือนนี้',
      'ไตรมาสนี้',
      ' ปีปัจจุบัน',
      'ปีก่อนหน้า',
      'เดือนนี้ถึงวันนี้',
      'ไตรมาสนี้ถึงวันนี้',
      'ปีนี้ถึงวันนี้',
      'ปีงบประมาณปัจจุบัน',
      'ปีงบประมาณก่อนหน้า',
      '12 เดือนย้อนหลัง',
    ]

    return {
      year,
      icons: {
        mdiCheck,
      },
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      name: 'Case',
      search: '',
      totalSales: 300000000,
      desserts: [
        {
          name: 'Frozen Yogurt',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Ice cream sandwich',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Eclair',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Cupcake',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Gingerbread',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Jelly bean',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Lollipop',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Honeycomb',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Donut',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'KitKat',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Lollipop',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'KitKat',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Donut',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'KitKat',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Jelly bean',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
        {
          name: 'Ice cream sandwich',
          note: 'ระบุรายละเอียด',
          remaining: 2,
          productMin: 100,
          productDisappear: 10,
        },
      ],
      headers: [
        {
          text: 'สินค้า',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'รายละเอียด', value: 'note' },
        { text: 'จำนวนคงเหลือ', value: 'remaining' },
        { text: 'สินค้าคงคลังขั้นต่ำ', value: 'productMin' },
        { text: 'สินค้าขาดหาย/เกิน', value: 'productDisappear' },
      ],
    }
  },
  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.bannerText {
  background-color: #8a8d93;
  width: 30rem;
  height: 4.5rem;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.bannerText h2,
.bannerText p {
  color: #f5f5f5 !important;
}
</style>
